import { createStore } from 'vuex'
import axios from 'axios';

export default createStore({
  state: {
    system: {
      version: '1.2.1',
      color1: '',
      color2: '',
      logo: '',
      icon: 'fa-house',
      // baseURL: 'https://ppanel.pauloferraco.com.br/',
      // baseURL: 'https://ppanel.radiohabblet.com.br/',
      baseURL: 'https://ppanel.bubblet.com.br/',
      client_id: 2, // 1 radio, 2 bub
      tema_colors: 3, // tema do site, 2 radio, 3 bub
      mobile: false,
      modulesE: [],
      dev: Boolean,
      apiEditor: '',
      editor: {
        language: 'pt_BR',
        plugins: 'print preview paste importcss searchreplace autolink autosave save directionality code textpattern visualblocks visualchars fullscreen image link media codesample table charmap hr pagebreak nonbreaking anchor insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars emoticons searchreplace',
        menubar: 'file edit view insert format tools table help',
        toolbar: 'undo redo | bold italic underline strikethrough | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | charmap emoticons | searchreplace fullscreen  preview save print | insertfile image media link anchor codesample | ltr rtl | pagebreak ',
        toolbar_sticky: true,
        autosave_ask_before_unload: true,
        autosave_interval: '30s',
        autosave_prefix: '{path}{query}-{id}-',
        autosave_restore_when_empty: true,
        autosave_retention: '2m',
        image_advtab: true,
        image_uploadtab: false,
        automatic_uploads: false,
        images_reuse_filename: true,
        convert_urls: false,
        file_picker_types: 'file image media',
        images_file_types: 'jpeg,jpg,jpe,jfi,jif,jfif,png,gif,bmp,webp',
        height: 600,
        quickbars_selection_toolbar: 'bold italic | quicklink h2 h3 blockquote quickimage quicktable',
        noneditable_noneditable_class: 'mceNonEditable',
        branding: false,
        importcss_append: true,
        toolbar_mode: 'sliding',
        contextmenu: 'link image imagetools table',
        content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }',
        forced_root_block: 'div'
        }
    },
    user: {
      id: 0,
      user: '',
      image: '',
      modder: Boolean
    },
    sysClient: [],
    titleModule: '',
    logged: false,
    sysActive: Boolean,
    navbar: false,
    notif: false,
    textA: null,
    textR: null,
    textW: null,
    modules: [],
    urlAPI: 'https://api.pauloferraco.com.br/v3/',
    urlImager: 'https://images.habblet.city/c_images/album1584/',
    dark: false
  },
  mutations: {
    tema(state){
      let root = document.documentElement;
      root.style.setProperty("--color1", state.system.color1)
      root.style.setProperty("--color2", state.system.color2)
    },
    Alert(state, text){
      state.textA = text;
      setTimeout(() => {
        state.textA = null;
      }, 3500);
      // this.$store.commit('Alert', req.data.text)
    },
    AlertRed(state, text){
      state.textR = text;

      setTimeout(() => {
        state.textR = null;
      }, 3500);
    },
    AlertWarning(state, text){
      state.textW = text;

      setTimeout(() => {
        state.textW = null;
      }, 3500);
    },
    modules(state, data){
      state.system.modulesE = data
    },
    modulesAll(state, data){
      state.modules = data
    },
    dark(state, bool){
      state.dark = bool
      localStorage.setItem('dark', bool)
      if(state.dark == true || bool == 'true'){
        document.body.classList.add("dark-mode");
      }else{
        document.body.classList.remove("dark-mode");
      }

      state.system.editor = {...state.system.editor,
        skin: state.dark ? 'oxide-dark' : 'oxide',
        content_css: state.dark ? 'dark' : 'default'
      }
    }
  },
  getters: {
  
  },
  actions: {
    async init({commit}){
      const req = await axios.get('async/init')
      commit('init', req.data)
    },
    async verPerm(store, data){
      const req = await axios.post('async_util/permissions/get', {
        dir: data
      })
      store.commit('Alert', req.data.text)
      return req.data.permission

      // if(store.state.modules.includes(data)){
      //   store.commit('Alert', '1')
      //   return true;
      // }
      // store.commit('Alert', '0')
      // return false
    },
    async modExtra({commit}){
      const req = await axios.get('async_util/modules/get')
      commit('modules', req.data)
    },
    async getModules(store){
      const req = await axios.get('async_util/modules/get_all')
      store.commit('modulesAll', req.data);
    },
    async getAccess({state}, id){
			let path = document.location.pathname ?? 0
			let p = path.split('/')[1] ?? id
			
			const active = await axios.post(state.urlAPI+'ppanel/access', {
				id: p
			});	

			if(p != ''){
				// if enviou id ou ta salvo
				localStorage.setItem('access', p)
			}
			state.sysActive = active.data
			state.sysClient = active.data.client
			
		},
  },
  modules: {
  }
})
