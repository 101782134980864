import { createRouter, createWebHistory } from 'vue-router'
import IndexMain from '../views/index.vue'

const routes = [
  {
    path: '/:client?',
    name: 'home',
    component: IndexMain
  },
  {
    path: '/uploads',
    name: 'UploadsPage',
    component: () => import(/* webpackChunkName: "uploads" */ '../views/uploads.vue')
  },
  {
    path: '/logs',
    name: 'LogsPage',
    component: () => import(/* webpackChunkName: "logs" */ '../modules/logs/logs')
  },
  {
    path: '/members',
    name: 'MembersPage',
    component: () => import(/* webpackChunkName: "membros" */ '../modules/admin/members/members.vue')
  },
  {
    path: '/modules',
    name: 'ChannelsPage',
    component: () => import(/* webpackChunkName: "modules" */ '../modules/admin/channels/channels.vue')
  },
  {
    path: '/offices',
    name: 'OfficePage',
    component: () => import(/* webpackChunkName: "offices" */ '../modules/admin/offices/offices.vue')
  },
  {
    path: '/category-offices',
    name: 'OfficeCat',
    component: () => import(/* webpackChunkName: "offices-cat" */ '../modules/admin/cat_offices/cat_offices')
  },
  {
    path: '/cat-channels',
    name: 'CatChannels',
    component: () => import(/* webpackChunkName: "cat_modules" */ '../modules/admin/cat_modules/cat_modules.vue')
  },
  {
    path: '/notify',
    name: 'NotifyPage',
    component: () => import(/* webpackChunkName: "notify" */ '../modules/outros/notifications/notify.vue')
  },
  {
    path: '/config-panel',
    name: 'ConfigPanel',
    component: () => import(/* webpackChunkName: "config-panel" */ '../modules/configs/config-panel.vue')
  },
  {
    path: '/config-site',
    name: 'ConfigSite',
    component: () => import(/* webpackChunkName: "config-site" */ '../modules/configs/config-site.vue')
  },
  {
    path: '/tema-panel',
    name: 'TemaPanel',
    component: () => import(/* webpackChunkName: "tema-panel" */ '../modules/configs/tema-panel.vue')
  },
  {
    path: '/tema-site',
    name: 'TemaSite',
    component: () => import(/* webpackChunkName: "config-site" */ '../modules/configs/tema-site')
  },
  {
    path: "/cat-pages-site",
    name: "CatPagesSite",
    component: () => import(/* webpackChunkName: "cat-pages" */ '../modules/admin/pages/cat-page/cat-pages')
  },
  {
    path: "/pages-site/:id",
    name: "PagesSite",
    component: () => import(/* webpackChunkName: "pages-site" */ '../modules/admin/pages/pages')
  },
  {
    path: "/notices",
    name: "NoticePage",
    component: () => import(/* webpackChunkName: "notices" */ '../modules/notice/notices')
  },

  // modulo news
  {
    path: '/post-news',
    name: 'PostNews',
    component: () => import(/* webpackChunkName: "post-news" */ '../modules/noticias/noticias/post-news')
  },
  {
    path: '/news_all',
    name: 'NewsAll',
    component: () => import(/* webpackChunkName: "news-all" */ '../modules/noticias/noticias/noticias_all')
  },
  {
    path: "/news-user",
    name: "NewsUser",
    component: () => import(/* webpackChunkName: "news-user" */ '../modules/noticias/noticias/noticias')
  },
  {
    path: '/category-news',
    name: 'CatNews',
    component: () => import(/* webpackChunkName: "cat_news" */ '../modules/noticias/cat-not/cat-news.vue')
  },

  // modules extras
  {
    path: '/category-topic',
    name: 'CatTopic',
    component: () => import(/* webpackChunkName: "topic" */ '../modules/topicos/cat-topic/cat-topic.vue')
  },
  {
    path: '/topicos-all',
    name: 'TopicAll',
    component: () => import(/* webpackChunkName: "topic" */ '../modules/topicos/topicos-all.vue')
  },
  {
    path: '/topics-user',
    name: 'TopicsUser',
    component: () => import(/* webpackChunkName: "topic" */ '../modules/topicos/topicos.vue')
  },
  {
    path: '/post-topicos',
    name: 'TopicPost',
    component: () => import(/* webpackChunkName: "topic" */ '../modules/topicos/post-topic.vue')
  },
  {
    path: '/modder-topic',
    name: 'ModTopic',
    component: () => import(/* webpackChunkName: "topic_mod" */ '../modules/topicos/edits/mod-topic.vue')
  },
  {
    path: '/topic-modder',
    name: 'TopicMod',
    component: () => import(/* webpackChunkName: "topic_mod" */ '../modules/topicos/topicos.vue')
  },
  {
    path: '/mod-user',
    name: 'ModUser',
    component: () => import(/* webpackChunkName: "mod_user" */ '../modules/moderacao/mod-user.vue')
  },
  {
    path: '/horarios',
    name: 'HorariosPage',
    component: () => import(/* webpackChunkName: "radio-time" */ '../modules/radio/horarios.vue')
  },
  {
    path: '/enter_live',
    name: 'EnterArPage',
    component: () => import(/* webpackChunkName: "radio" */ '../modules/radio/enter_live.vue')
  },
  {
    path: '/uncheck_hour',
    name: 'UncheckPage',
    component: () => import(/* webpackChunkName: "radio-time" */ '../modules/radio/desmark_hour.vue')
  },
  {
    path: '/generate-presence',
    name: 'MarkPresence',
    component: () => import(/* webpackChunkName: "radio" */ '../modules/radio/mark_presence')
  },
  {
    path: '/announcer-reports',
    name: 'ReportsModule',
    component: () => import(/* webpackChunkName: "radio" */ '../modules/radio/reports')
  },
  {
    path: '/slides',
    name: 'SlidePage',
    component: () => import(/* webpackChunkName: "slides" */ '../modules/site/slides/slide.vue')
  },
  {
    path: '/items_free',
    name: 'FreePage',
    component: () => import(/* webpackChunkName: "items_free" */ '../modules/site/frees/frees.vue')
  },
  {
    path: '/category-arts',
    name: 'CatArts',
    component: () => import(/* webpackChunkName: "arts" */ '../modules/site/arts/cat-art/cat-arts.vue')
  },
  {
    path: '/arts-modder',
    name: 'ArtsMod',
    component: () => import(/* webpackChunkName: "arts" */ '../modules/site/arts/arts/arts.vue')
  },
  {
    path: '/all-arts',
    name: 'AllArts',
    component: () => import(/* webpackChunkName: "arts" */ '../modules/site/arts/arts/arts_all.vue')
  },
  {
    path: '/edit-arts/:id',
    name: 'EditArt',
    component: () => import(/* webpackChunkName: "arts" */ '../modules/site/arts/arts/edit-art.vue')
  },
  {
    path: '/records',
    name: 'RecordsPage',
    component: () => import(/* webpackChunkName: "records" */ '../modules/site/records/records.vue')
  },
  {
    path: '/spotlight',
    name: 'SpotPage',
    component: () => import(/* webpackChunkName: "spotlight" */ '../modules/site/spotlight/spotlight')
  },
  {
    path: '/store',
    name: 'StorePage',
    component: () => import(/* webpackChunkName: "store" */ '../modules/site/loja/loja')
  },
  {
    path: '/store-sold',
    name: 'StoresoldPage',
    component: () => import(/* webpackChunkName: "store" */ '../modules/site/loja/loja-vend')
  },
  {
    path: '/values',
    name: 'ValuesPage',
    component: () => import(/* webpackChunkName: "values" */ '../modules/site/valores/valores')
  },
  {
    path: '/cat-values',
    name: 'ValuesCat',
    component: () => import(/* webpackChunkName: "values" */ '../modules/site/valores/cat-valores/cat-val')
  },
  {
    path: '/tickets',
    name: 'TicketsPage',
    component: () => import(/* webpackChunkName: "tickets" */ '../modules/site/tickets/tickets')
  },
  {
    path: '/feed',
    name: 'FeedPage',
    component: () => import(/* webpackChunkName: "feed" */ '../modules/feed/feed')
  },
  {
    path: '/wireds',
    name: 'WiredsPage',
    component: () => import(/* webpackChunkName: "wireds" */ '../modules/site/wireds/wireds')
  },
  {
    path: '/hours-promotor',
    name: 'PromoHours',
    component: () => import(/* webpackChunkName: "hours_promo" */ '../modules/outros/hours_promo/hours')
  },
  {
    path: '/descmark-promotor',
    name: 'DesmarkPromo',
    component: () => import(/* webpackChunkName: "hours_promo" */ '../modules/outros/hours_promo/desmark')
  },
  {
    path: '/hours-marketing',
    name: 'MarkHours',
    component: () => import(/* webpackChunkName: "hours_mark" */ '../modules/outros/hours_mark/hours')
  },
  {
    path: '/descmark-marketing',
    name: 'DesmarkMark',
    component: () => import(/* webpackChunkName: "hours_mark" */ '../modules/outros/hours_mark/desmark')
  },
  {
    path: '/pedidos',
    name: 'PedidosPage',
    component: () => import(/* webpackChunkName: "pedidos" */ '../modules/site/pedidos/requests.vue')
  },
  {
    path: '/vinhetas',
    name: 'VinhetaPage',
    component: () => import(/* webpackChunkName: "vinhetas" */ '../modules/site/vinhetas/vinhetas')
  },
  {
    path: '/vinhetas-view',
    name: 'VinhetasView',
    component: () => import(/* webpackChunkName: "vinhetas" */ '../modules/site/vinhetas/vinhetas-view')
  },
  {
    path: '/podcast',
    name: 'PodcastPage',
    component: () => import(/* webpackChunkName: "podcast" */ '../modules/site/podcast/podcasts')
  },
  {
    path: '/badges-site',
    name: 'BadgesPage',
    component: () => import(/* webpackChunkName: "badges" */ '../modules/site/badges/badges')
  },
  {
    path: '/all-comments',
    name: 'CommentsPage',
    component: () => import(/* webpackChunkName: "comments" */ '../modules/site/comments/comments')
  },
  {
    path: '/payment',
    name: 'PaymentPage',
    component: () => import(/* webpackChunkName: "payment" */ '../modules/payment/payment')
  },

  {
    path: '/fasites',
    name: 'FasitesModule',
    component: () => import(/* webpackChunkName: "fasites" */ '../modules/outros/fasites/fasites')
  },
  {
    path: '/promotor',
    name: 'PromModule',
    component: () => import(/* webpackChunkName: "pontos-extra" */ '../modules/outros/promotor/promotores')
  },
  {
    path: '/marketing',
    name: 'MarketingModule',
    component: () => import(/* webpackChunkName: "pontos-extra" */ '../modules/outros/marketing/marketing')
  },
  {
    path: '/programadores',
    name: 'ProgModule',
    component: () => import(/* webpackChunkName: "pontos-extra" */ '../modules/outros/r_programador/progs')
  },
  {
    path: '/construtores',
    name: 'ConstModule',
    component: () => import(/* webpackChunkName: "pontos-extra" */ '../modules/outros/r_construtor/construtores')
  },
  {
    path: '/votes-camp',
    name: 'VotesCampModule',
    component: () => import(/* webpackChunkName: "votes" */ '../modules/votes/camps')
  },
  {
    path: '/votes/:camp',
    name: 'VotesModule',
    component: () => import(/* webpackChunkName: "votes" */ '../modules/votes/cat-votes')
  },
  {
    path: '/highlights',
    name: 'HighLightsModule',
    component: () => import(/* webpackChunkName: "highlights" */ '../modules/outros/highlights/highlights')
  }

  // painel api
  // {
  //   path: '/payment',
  //   name: 'PayMod',
  //   component: () => import(/* webpackChunkName: "pay-module" */ '../modules/payment/payment')
  // },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
